<script setup lang="ts">
import { computed } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import { formatPoints } from '/~/utils/points'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { type TSummary } from '/~/templates/bill-payments-v1_5/modals/payments-make/payments-make-summary.mdl.vue'

const props = withDefaults(
  defineProps<{
    payFrom: TSummary['payingFrom']
    totalPayFrom?: number
    totalFees?: number
  }>(),
  {
    totalPayFrom: 0,
    totalFees: 0,
  }
)

const method = computed(() => props.payFrom.method)
const label = computed(() => {
  const { type, brand, number, accountName, accountNumber } = method.value ?? {}

  switch (type) {
    case PaymentMethodType.payId:
      return 'PayID'
    case PaymentMethodType.creditCard:
      return `${brand} ****${String(number).slice(-4)}`
    case PaymentMethodType.bankAccount:
      return `${accountName} ****${accountNumber.slice(-4)}`
    default:
      return null
  }
})
</script>

<template>
  <div class="space-y-1 py-6">
    <div class="text-lg font-bold">Paying from</div>

    <div v-if="payFrom.pointsToUse" class="flex items-center justify-between">
      <div class="flex flex-col text-eonx-neutral-600 xs:flex-row">
        <span>Points redeemed</span>
        <span class="mx-1">-</span>
        <span class="text-sm font-bold xs:text-base">
          {{ formatPoints(payFrom.pointsToUse) }}PTS
        </span>
      </div>
      <div>{{ formatDollar(payFrom.calculatedAmount) }}</div>
    </div>

    <div v-if="label" class="flex items-center justify-between">
      <div class="flex flex-col text-eonx-neutral-600 xs:flex-row">
        <span>{{ label }}</span>
        <span v-if="payFrom.pointsToUse" class="text-sm xs:text-base">
          &nbsp;(Points earned on this amount)
        </span>
      </div>
      <div>{{ formatDollar(totalPayFrom) }}</div>
    </div>

    <div v-if="label && totalFees" class="flex items-center justify-between">
      <div class="flex flex-col text-eonx-neutral-600 xs:flex-row">
        <span>{{ label }}</span>
        <span class="text-sm xs:text-base">&nbsp;(Total fees)</span>
      </div>
      <div>{{ formatDollar(totalFees) }}</div>
    </div>
  </div>
</template>
